import React, { useState } from "react";
import "./inferenceDetailsComponent.css";
import { Divider, IconButton, Typography, Tab, Tabs } from "@mui/material";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { nextSteps } from "../../utlis";


// const handleNextDate = (left, right) => {
//     const currentDate = new Date();
//     let nextStepDate = new Date();
//     let message = "";
//     if(
//         left === "Hazy image (due to cataract or significant corneal opacity)" ||
//         right === "Hazy image (due to cataract or significant corneal opacity)"||
//         left === "Severe Diabetic Retinopathy" ||
//         right === "Severe Diabetic Retinopathy" ||
//         left === "Proliferative Diabetic Retinopathy" ||
//         right === "Proliferative Diabetic Retinopathy"
//     ) {
//         nextStepDate.setMonth(currentDate.getMonth() + 3);
//         message = "Referral required immediately  and ask for a follow up visit after 3 Months on";
//     }
//     else if (left === "Moderate Diabetic Retinopathy" || right === "Moderate Diabetic Retinopathy") {
//         nextStepDate.setMonth(currentDate.getMonth() + 6);
//         message = "Referral required within 3-6 months and ask for a follow up visit after 6 Months on";
//     }
//     else if (
//         left === "No Diabetic Retinopathy" ||
//         right === "No Diabetic Retinopathy" ||
//         left === "Mild Diabetic Retinopathy" ||
//         right === "Mild Diabetic Retinopathy"
//     ) {
//         nextStepDate.setFullYear(currentDate.getFullYear() + 1);
//         message = "Referral not required and ask for a follow up visit after a year on";
//     }

//     const formattedDate = nextStepDate.toDateString().split(' ').slice(1).join(' ');

//     return { date: formattedDate, message };
// };

const eyeInferenceDiv = (inf) => {
    if(inf === "No Diabetic Retinopathy") {
        return `eyeInferenceDivNoDR`;
    }
    if (inf === "Mild Diabetic Retinopathy") {
        return `eyeInferenceDivMildDR`;
    }
    if (inf === "Moderate Diabetic Retinopathy") {
        return `eyeInferenceDivModDR`;
    }
    if(inf === "Proliferative Diabetic Retinopathy") {
        return `eyeInferenceDivProDR`;
    }
    if(inf === "Severe Diabetic Retinopathy") {
        return `eyeInferenceDivSevDR`;
    }
    if(inf === "Hazy image (due to cataract or significant corneal opacity)") {
        return `eyeInferenceDivHazy`;
    }
};

const eyeInferenceText = (inf) => {
    if(inf === "No Diabetic Retinopathy") {
        return `eyeInferenceTextNoDR`;
    }
    else if (inf === "Mild Diabetic Retinopathy") {
        return `eyeInferenceTextMildDR`;
    }
    else if (inf === "Moderate Diabetic Retinopathy") {
        return `eyeInferenceTextModDR`;
    }
    else if(inf === "Proliferative Diabetic Retinopathy") {
        return `eyeInferenceTextProDR`;
    }
    else if(inf === "Severe Diabetic Retinopathy") {
        return `eyeInferenceTextSevDR`;
    }
    if(inf === "Hazy image (due to cataract or significant corneal opacity)") {
        return `eyeInferenceTextHazy`;
    }
};

const requiredInferenceDiv = (inf) => {
    if(inf === "Required") {
        return "reqInfDiv";
    }
    else if(inf === "Not Required") {
        return "notRegInfDiv";
    }
};

const requiredInferenceText = (inf) => {
    if(inf === "Required") {
        return "reqInfText";
    }
    else if (inf === "Not Required") {
        return "notReqInfText";
    }
};

const ImageDiv = (inf) => {
    if(inf === "No Diabetic Retinopathy") {
        return `imageDivNoDR`;
    }
    if (inf === "Mild Diabetic Retinopathy") {
        return `imageDivMildDR`;
    }
    if (inf === "Moderate Diabetic Retinopathy") {
        return `imageDivModDR`;
    }
    if(inf === "Proliferative Diabetic Retinopathy") {
        return `imageDivProDR`;
    }
    if(inf === "Severe Diabetic Retinopathy") {
        return `imageDivSevDR`;
    }
    if(inf === "Hazy image (due to cataract or significant corneal opacity)") {
        return `imageDivHazy`;
    }
};

const InferenceDetailsComponent = ({edit, handleImageEditClick, leftImage, rightImage, leftInference, rightInference, required}) => {
    const {date, message} = nextSteps(leftInference, rightInference);

    const [view, setView] = useState("right");

    const handleTabChange = (event, newValue) => {
        setView(newValue);
    };

    return (
        <div>
            <div className="web-view">
                <div style={{ display: "flex", justifyContent: "space-between", padding: "0 2%" }}>
                    <div>
                        <div style={{ display: "flex" }}>
                            <Typography fontWeight={600} fontSize="20px" color="#051572">Right Eye</Typography>
                            {edit ? <IconButton onClick={handleImageEditClick}>
                                <EditOutlinedIcon fontSize="small" sx={{ color: "#051572" }} />
                            </IconButton> : null}
                        </div>
                        <div style={{
                            width: "25vw",
                            height: "32vh",
                            backgroundColor: "black",
                            border: "2px solid",
                            borderRadius: "8px",
                            boxShadow: "0px 2px 4px 1px rgba(0, 133, 13, 0.09), 0px 0px 6px 2px rgba(56, 142, 60, 0.26)",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                            className={ImageDiv(rightInference)}
                        >
                            <img alt="Right Eye" src={rightImage} style={{ height: "90%", width: "90%" }} />
                        </div>
                        <div style={{ padding: "2% 0" }}>
                            <Typography fontWeight="bold" fontSize="16px">AI Generated Inference:</Typography>
                            <div
                                className={eyeInferenceDiv(rightInference)}
                                style={{
                                    borderRadius: "8px",
                                    border: "1px solid",
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "fit-content",
                                    padding: "1.5%"
                                }}
                            >
                                <Typography
                                    className={eyeInferenceText(rightInference)}
                                    fontWeight={400}
                                    fontSize="16px"
                                >
                                    {rightInference === "Hazy image (due to cataract or significant corneal opacity)" ? "Hazy Image (No AI Inference)" : rightInference}
                                </Typography>
                            </div>
                        </div>
                    </div>
                    <Divider orientation="vertical" variant="middle" flexItem />
                    <div>
                        <div style={{ display: "flex" }}>
                            <Typography fontWeight={600} fontSize="20px" color="#051572">Left Eye</Typography>
                            {edit ? <IconButton onClick={handleImageEditClick}>
                                <EditOutlinedIcon fontSize="small" sx={{ color: "#051572" }} />
                            </IconButton> : null}
                        </div>
                        <div style={{
                            width: "25vw",
                            height: "32vh",
                            backgroundColor: "black",
                            border: "2px solid",
                            borderRadius: "8px",
                            boxShadow: "0px 2px 4px 1px rgba(0, 133, 13, 0.09), 0px 0px 6px 2px rgba(56, 142, 60, 0.26)",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                            className={ImageDiv(leftInference)}
                        >
                            <img alt="Left Eye" src={leftImage} style={{ height: "90%", width: "90%" }} />
                        </div>
                        <div style={{ padding: "2% 0" }}>
                            <Typography fontWeight="bold" fontSize="16px">AI Generated Inference:</Typography>
                            <div
                                className={eyeInferenceDiv(leftInference)}
                                style={{
                                    borderRadius: "8px",
                                    border: "1px solid",
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "fit-content",
                                    padding: "1.5%"
                                }}
                            >
                                <Typography
                                    className={eyeInferenceText(leftInference)}
                                    fontWeight={400}
                                    fontSize="16px"
                                >
                                    {leftInference === "Hazy image (due to cataract or significant corneal opacity)" ? "Hazy Image (No AI Inference)" : leftInference}
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mobile-view">
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            {/* <Typography fontWeight={600} fontSize="20px" color="#051572">Right Eye</Typography> */}
                <Tabs value={view} onChange={handleTabChange} sx={{'& .MuiTabs-indicator': {backgroundColor: "#051572",},}}>
                    <Tab value="right" label="Right Eye" sx={{color: view === 'right' ? "#051572" : "grey",'&.Mui-selected': {color: "#051572",},}} />
                    <Tab value="left" label="Left Eye" sx={{color: view === 'left' ? "#051572" : "grey",'&.Mui-selected': {color: "#051572",},}} />
                </Tabs>
                {edit ? <IconButton onClick={handleImageEditClick}>
                <EditOutlinedIcon fontSize="small" sx={{ color: "#051572" }} />
                </IconButton> : null}
            </div>

                {view === "left" && (
                    <div>
                        <div style={{
                            width: "100%",
                            height: "32vh",
                            backgroundColor: "black",
                            border: "2px solid",
                            borderRadius: "8px",
                            boxShadow: "0px 2px 4px 1px rgba(0, 133, 13, 0.09), 0px 0px 6px 2px rgba(56, 142, 60, 0.26)",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: "1rem",
                        }}
                            className={ImageDiv(leftInference)}
                        >
                            <img alt="Left Eye" src={leftImage} style={{ height: "90%", width: "90%" }} />
                        </div>
                        <div style={{ padding: "2% 0" }}>
                            <Typography fontWeight="bold" fontSize="16px">AI Generated Inference:</Typography>
                            <div
                                className={eyeInferenceDiv(leftInference)}
                                style={{
                                    borderRadius: "8px",
                                    border: "1px solid",
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "fit-content",
                                    padding: "1.5%"
                                }}
                            >
                                <Typography
                                    className={eyeInferenceText(leftInference)}
                                    fontWeight={400}
                                    fontSize="16px"
                                >
                                    {leftInference === "Hazy image (due to cataract or significant corneal opacity)" ? "Hazy Image (No AI Inference)" : leftInference}
                                </Typography>
                            </div>
                        </div>
                    </div>
                )}
                {view === "right" && (
                    <div>
                        <div style={{
                            width: "100%",
                            height: "32vh",
                            backgroundColor: "black",
                            border: "2px solid",
                            borderRadius: "8px",
                            boxShadow: "0px 2px 4px 1px rgba(0, 133, 13, 0.09), 0px 0px 6px 2px rgba(56, 142, 60, 0.26)",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: "1rem",
                        }}
                            className={ImageDiv(rightInference)}
                        >
                            <img alt="Right Eye" src={rightImage} style={{ height: "90%", width: "90%" }} />
                        </div>
                        <div style={{ padding: "2% 0" }}>
                            <Typography fontWeight="bold" fontSize="16px">AI Generated Inference:</Typography>
                            <div
                                className={eyeInferenceDiv(rightInference)}
                                style={{
                                    borderRadius: "8px",
                                    border: "1px solid",
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "fit-content",
                                    padding: "1.5%"
                                }}
                            >
                                <Typography
                                    className={eyeInferenceText(rightInference)}
                                    fontWeight={400}
                                    fontSize="16px"
                                >
                                    {rightInference === "Hazy image (due to cataract or significant corneal opacity)" ? "Hazy Image (No AI Inference)" : rightInference}
                                </Typography>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div style={{ padding: "1% 2%" }}>
                <div>
                    <Typography fontWeight="bold" fontSize="16px">AI Generated Analysis</Typography>
                    <Typography color="#404040" fontWeight={400} fontSize="16px">
                        {rightInference} in the right eye, {leftInference} in the left eye. further medical examination is suggested.
                    </Typography>
                </div>
            </div>
            <div style={{ padding: "1% 2%" }}>
                <div>
                    <Typography fontWeight="bold" fontSize="16px">AI Generated Referral Advice:</Typography>
                    <div
                        className={requiredInferenceDiv(required)}
                        style={{
                            borderRadius: "8px",
                            width: "fit-content",
                            padding: "1%"
                        }}
                    >
                        <Typography fontSize="16px" fontWeight={400} className={requiredInferenceText(required)}>{required}</Typography>
                    </div>
                </div>
            </div>
            <div style={{ padding: "1% 2%" }}>
                <div>
                    <Typography fontSize="16px" fontWeight="bold">Next Steps</Typography>
                    <span>{message} <u><strong>on {date}</strong></u>, and suggest them to monitor their sugar level.</span>
                </div>
            </div>
        </div>
    );
    
}

export default InferenceDetailsComponent