import React, { useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, IconButton, Stack, Typography, useTheme, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SubmitReportDialog from "./submitReportDialog";
import PatientDetailsComponent from "../patientDetailsComponent/patientDetailsComponent";
import config from "../../config";
import axios from "axios";
import LoadingComponent from "../loadingComponent/loadingComponent";
import InferenceDetailsComponent from "../inferenceDetailsComponent/inferenceDetailsComponent";
import HealthDetailsComponent from "../healthDetailsComponent/healthDetailsComponent";
import DisclaimerComponent from "../disclaimerComponent/disclaimerComponent";
/* eslint react-hooks/exhaustive-deps: "off" */
const PatientInference = ({
    formik,
    inference,
    handleExit,
    handlePatientDetailsEditClick,
    handleImageEditClick,
    handleHealthEditClick,
    handleSubmitReport,
    submittingReport,
}) => {
    const [openSubmitDialog, setOpenSubmitDialog] = useState(false);
    const handleSubmitDialogClose = () => {
        // console.log(inference);
        setOpenSubmitDialog(false);
    };

    const [visitNumber, setVisitNumber] = useState(null);
    useEffect(() => {
        const header = {"Authorization": "Token "+localStorage.getItem("optha-token")};
        axios
        .get(`${config.API_BASE_URL}/api/reports/getreport/?patient_id=${localStorage.getItem("patient_id")}`, {headers: header})
        .then((response) => {
            setVisitNumber(response.data.count);
            if(formik.values.pat_info.referredBy.docName === "" && response.data.count > 0) {
                formik.setFieldValue("pat_info.referredBy.docName", response.data.results[0].doctor.name)
            }
        })
        .catch((error) => console.log(error));
    }, []);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <div>
        <div>
        <Stack direction="row" padding="10px 5%" justifyContent="space-between" alignItems="center" >
            {isMobile === false && (
                <Typography fontSize="28px" fontWeight="500" lineHeight="31px">
                AI Inference
                </Typography>
            )}
            <div style={{ marginLeft: 'auto' }}>
                <Button
                    className='exitButton'
                    sx={{ borderRadius: "8px", color: "#0D1E83", border: "1px solid #0D1E83" }}
                    endIcon={<CloseIcon />}
                    onClick={() => {handleExit()}}
                >
                    Exit
                </Button>
            </div>
        </Stack>
        </div>
        <div style={{padding: isMobile ? "0%" : "2.5% 12.5%" }}>
            <Box sx={{
                border: "1px solid var(--BoxStroke, rgba(0, 0, 0, 0.05))",
                borderRadius: "8px",
                background: "var(--White, #FFF)",
                boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.08), 0px 0px 6px 0px rgba(0, 0, 0, 0.02)",
            }}>
                <Box width="100%" sx={{background: "rgba(189, 215, 242, 0.14)"}}>
                    <PatientDetailsComponent
                        edit={true}
                        handlePatientDetailsEditClick={handlePatientDetailsEditClick}
                        pat_name={formik.values.pat_info.name}
                        pat_age={formik.values.pat_info.age}
                        pat_sex={formik.values.pat_info.gender}
                        visitNumber={visitNumber}
                        visitDate={new Date().toDateString()}
                        pat_id={formik.values.pat_info.otherId === "" ? "N/A" : formik.values.pat_info.otherId}
                        pat_phone={formik.values.pat_info.number === "" ? "N/A" : formik.values.pat_info.number}
                        docName={formik.values.pat_info.referredBy.docName === "" ? "N/A" : formik.values.pat_info.referredBy.docName}
                    />
                </Box>
                <Box sx={{padding: "1% 7.5%"}}>
                    <InferenceDetailsComponent
                        edit={true}
                        handleImageEditClick={handleImageEditClick}
                        leftImage={URL.createObjectURL(formik.values.image_file.left.image)}
                        rightImage={URL.createObjectURL(formik.values.image_file.right.image)}
                        leftInference={inference.left}
                        rightInference={inference.right}
                        required={inference.req}
                    />
                </Box>
                <Accordion defaultExpanded={true}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{padding: "0 7.5%", display: "flex", alignItems: "center", background: "#BDD7F21F"}}>
                        <Typography fontWeight={600} fontSize="20px" color="#181818">Health Information</Typography>
                        <IconButton onClick={handleHealthEditClick}><EditOutlinedIcon fontSize="small" /></IconButton>
                    </AccordionSummary>
                    <AccordionDetails sx={{padding: "0 7.5%"}}>
                        {/* <PatientHealthInference formik={formik}/> */}
                        <HealthDetailsComponent health_info={formik.values.health_info} />
                    </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded={true}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{padding: "0 7.5%", display: "flex", alignContent: "center", background: "#BDD7F21F"}}>
                        <Typography fontWeight={600} fontSize="20px" color="#181818">Disclaimer</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{padding: "2% 7.5%"}}>
                        <DisclaimerComponent />
                    </AccordionDetails>
                </Accordion>
                <div style={{width: "100%", display: "flex"}}>
                    <div style={{width: "100%", display: "flex", justifyContent: "end", padding: "1.5% 7.5%"}}>
                        <Button
                            onClick={() => setOpenSubmitDialog(true)}
                            endIcon={<ArrowForwardIcon />}
                            sx={{
                                borderRadius: "8px",
                                border: "1px solid var(--White, #FFF)",
                                background: "var(--Gradient, linear-gradient(270deg, #051572 7.32%, #0820B4 92.68%))",
                                color: "#FFFFFF",
                                boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.08), 0px 0px 6px 0px rgba(0, 0, 0, 0.02)",
                            }}
                        >
                            Submit Report
                        </Button>
                    </div>
                </div>
                <SubmitReportDialog open={openSubmitDialog} handlePreviewClose={handleSubmitDialogClose} handleSubmitReport={handleSubmitReport}/>
                <LoadingComponent open={submittingReport} text={true} />
            </Box>
        </div>
    </div>
  )
}

export default PatientInference