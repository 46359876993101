import React, { useEffect, useState } from "react";
import { Button, Checkbox, Container, FormControlLabel, FormGroup, Grid, Radio, RadioGroup, TextField, Typography, useTheme, useMediaQuery } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
/* eslint react-hooks/exhaustive-deps: "off" */

const HealthInformation = ({handleNext, handleBack, formik}) => {
    const [diabetesShow, setDiabetesShow] = useState(formik.values.health_info.preDiagnosedDiabetes === "yes" ? true : false || false);
    const [disabledButton, setDisabledButton] = useState(true);

    const handleProceedDisabled = () => {
        if(formik.values.health_info.preDiagnosedDiabetes && formik.values.health_info.visualAcuity.left && formik.values.health_info.visualAcuity.right && !formik.errors?.health_info) {
            if(formik.values.health_info.preDiagnosedDiabetes === "yes" && formik.values.health_info.durationDiabetesDetection && formik.values.health_info.diabetesType) {
                setDisabledButton(false)
            }
            else if (formik.values.health_info.preDiagnosedDiabetes === "no" || formik.values.health_info.preDiagnosedDiabetes === "don't know") {
                setDisabledButton(false);
            }
            else {
                setDisabledButton(true);
            }
        }
        else {
            setDisabledButton(true);
        }
    };

    useEffect(() => {
        handleProceedDisabled();
        formik.values.health_info.preDiagnosedDiabetes === "yes" ? setDiabetesShow(true) : setDiabetesShow(false);
    }, [formik.values.health_info, formik.errors?.health_info]);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    function handleYesClick () {
        setDiabetesShow(true);
    }

    function handleNoClick () {
        setDiabetesShow(false);
    }

    const handleComplicationCheckBox = (value) => {
        const complication = formik.values.health_info.otherComplications;
        if(value === 'none') {
            if(complication.includes('none')) {
                const newComplications = complication.filter(item => item !== 'none');
                formik.setFieldValue("health_info.otherComplications", newComplications);
            } else {
                formik.setFieldValue("health_info.otherComplications", ['none']);
            }
        } else {
            const newComplications = complication.includes(value)
            ? complication.filter(item => item !== value)
            : [...complication, value];
            if (newComplications.includes('none')) {
                const withoutNone = newComplications.filter(item => item !== 'none');
                formik.setFieldValue('health_info.otherComplications', withoutNone);
              } else {
                formik.setFieldValue('health_info.otherComplications', newComplications);
              }
        }
    };

    const handleFaimlyCheckBox = (value) => {
        const family = formik.values.health_info.familyHistory;
        if(value === 'none') {
            if(family.includes('none')) {
                const newfamily = family.filter(item => item !== 'none');
                formik.setFieldValue("health_info.familyHistory", newfamily);
            } else {
                formik.setFieldValue("health_info.familyHistory", ['none']);
            }
        } else {
            const newfamily = family.includes(value)
            ? family.filter(item => item !== value)
            : [...family, value];
            if (newfamily.includes('none')) {
                const withoutNone = newfamily.filter(item => item !== 'none');
                formik.setFieldValue('health_info.familyHistory', withoutNone);
              } else {
                formik.setFieldValue('health_info.familyHistory', newfamily);
              }
        }
    }

    function showDiabetesField () {
        return (
          <>
            <Grid item container direction={isMobile ? "column" : "row"} alignItems={isMobile ? "left" : "center"} sx={isMobile && {marginBottom:1}}>
              <Typography width={isMobile ? "100vw" : "13vw"}>
                Duration of Diabetes Detection*
              </Typography>
              <TextField
                type="number"
                placeholder="XX"
                id="health_info.durationDiabetesDetection"
                name="health_info.durationDiabetesDetection"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.health_info.durationDiabetesDetection}
                variant="outlined"
                InputProps={{ endAdornment: "Years" }}
                sx={{
                  border: "1px solid #0000000D",
                  borderRadius: "4px",
                  width: isMobile ? "100%" : "10vw",
                }}
                error={
                  formik.touched.health_info?.durationDiabetesDetection &&
                  formik.errors.health_info?.durationDiabetesDetection
                }
              />
            </Grid>
            {formik.touched.health_info?.durationDiabetesDetection &&
            formik.errors.health_info?.durationDiabetesDetection ? (
              <Typography color="red">
                {formik.errors.health_info.durationDiabetesDetection}
              </Typography>
            ) : null}
            <Grid item container direction={isMobile ? "column" : "row"} alignItems={isMobile ? "left" : "center"} sx={isMobile && {marginBottom:1}}>
              <Typography width={isMobile ? "100vw" : "13vw"}>Diabetes Type*</Typography>
              <RadioGroup
                row
                id="health_info.diabetesType"
                name="health_info.diabetesType"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.health_info.diabetesType}
                aria-required
                sx={{ marginLeft: "10px" }}
              >
                <FormControlLabel
                  value="type1"
                  control={<Radio />}
                  label="Type 1"
                  sx={{
                    border: "1px solid rgba( 0, 0, 0, 0.05)",
                    borderRadius: "8px",
                    paddingRight: isMobile? "4px":"10px",
                    boxShadow: "1px 1px 10px 0 #4040400D",
                  }}
                />
                <FormControlLabel
                  value="type2"
                  control={<Radio />}
                  label="Type 2"
                  sx={{
                    border: "1px solid rgba( 0, 0, 0, 0.05)",
                    borderRadius: "8px",
                    paddingRight: isMobile? "4px":"10px",
                    marginLeft: isMobile? "1px":"8px",
                    boxShadow: "1px 1px 10px 0 #4040400D",
                  }}
                />
              </RadioGroup>
            </Grid>
            {formik.touched.health_info?.diabetesType &&
            formik.errors.health_info?.diabetesType ? (
              <Typography color="red">
                {formik.errors.health_info.diabetesType}
              </Typography>
            ) : null}
          </>
        );
    }
  return (
    <div>
        <Container sx={{padding: !isMobile && "0px 24px"}}>
            <Grid container spacing={1}>
                <Grid item container direction={isMobile ? "column" : "row"} alignItems={isMobile ? "left" : "center"} sx={isMobile && {marginBottom:1}}>
                    <Typography width={isMobile ? "100vw" : "13vw"}>Pre-diagnosed Diabetes*</Typography>
                    <RadioGroup row id="health_info.preDiagnosedDiabetes" name="health_info.preDiagnosedDiabetes" onChange={formik.handleChange} value={formik.values.health_info.preDiagnosedDiabetes} aria-required sx={{marginLeft: "10px"}}>
                            <FormControlLabel value="yes" control={<Radio />} label="Yes" onClick={handleYesClick} sx={{border: "1px solid rgba( 0, 0, 0, 0.05)", borderRadius: "8px", paddingRight: isMobile? "4px":"10px", boxShadow: "1px 1px 10px 0 #4040400D"}}/>
                            <FormControlLabel value="no" control={<Radio />} label="No" onClick={handleNoClick} sx={{border: "1px solid rgba( 0, 0, 0, 0.05)", borderRadius: "8px", paddingRight: isMobile? "4px":"10px", boxShadow: "1px 1px 10px 0 #4040400D", marginLeft: isMobile? "1px":"8px"}}/>
                            <FormControlLabel value="don't know" control={<Radio />} label="Don't Know" onClick={handleNoClick} sx={{border: "1px solid rgba( 0, 0, 0, 0.05)", borderRadius: "8px", paddingRight: isMobile? "4px":"10px", boxShadow: "1px 1px 10px 0 #4040400D", marginLeft: isMobile? "1px":"8px"}}/>
                    </RadioGroup>
                </Grid>
                {diabetesShow && showDiabetesField()}
                <Grid item container direction={isMobile ? "column" : "row"} alignItems={isMobile ? "left" : "center"} sx={isMobile && {marginBottom:1}}>
                    <Typography width={isMobile ? "100vw" : "13vw"}>Hb A1 C Level</Typography>
                    <TextField
                        type="number"
                        placeholder="XX"
                        variant="outlined"
                        id="health_info.HbA1CLevel"
                        name="health_info.HbA1CLevel"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.health_info.HbA1CLevel}
                        InputProps={{ endAdornment: 'mg/dL'}}
                        sx={{
                            border: "1px solid #0000000D",
                            borderRadius: "4px",
                            width: isMobile ? "100%" : "10vw"
                        }}
                        error={formik.touched.health_info?.HbA1CLevel && formik.errors.health_info?.HbA1CLevel}
                    />
                </Grid>
                {
                    formik.touched.health_info?.HbA1CLevel && formik.errors.health_info?.HbA1CLevel ?
                    (<Typography color="red" >{formik.errors.health_info.HbA1CLevel}</Typography>) : null
                }

                {isMobile ? (
                <Grid container alignItems="left" sx={{marginLeft: 1, marginBottom:1}}>
                    <Grid item xs={6}>
                    <Typography width="40vw">Systolic Pressure</Typography>
                    <TextField
                        type="number"
                        placeholder="XX"
                        variant="outlined"
                        id="health_info.systolicBloodPressure"
                        name="health_info.systolicBloodPressure"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.health_info.systolicBloodPressure}
                        InputProps={{ endAdornment: 'mmHg'}}
                        sx={{
                        border: "1px solid #0000000D",
                        borderRadius: "4px",
                        width: "90%" // Adjusted for better layout control on mobile
                        }}
                        error={formik.touched.health_info?.systolicBloodPressure && Boolean(formik.errors.health_info?.systolicBloodPressure)}
                    />
                    {formik.touched.health_info?.systolicBloodPressure && formik.errors.health_info?.systolicBloodPressure && (
                        <Typography color="error">{formik.errors.health_info.systolicBloodPressure}</Typography>
                    )}
                    </Grid>
                    <Grid item xs={6}>
                    <Typography width="40vw">Diastolic Pressure</Typography>
                    <TextField
                        type="number"
                        placeholder="XX"
                        variant="outlined"
                        id="health_info.diastolicBloodPressure"
                        name="health_info.diastolicBloodPressure"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.health_info.diastolicBloodPressure}
                        InputProps={{ endAdornment: 'mmHg'}}
                        sx={{
                        border: "1px solid #0000000D",
                        borderRadius: "4px",
                        width: "90%" // Adjusted for better layout control on mobile
                        }}
                        error={formik.touched.health_info?.diastolicBloodPressure && Boolean(formik.errors.health_info?.diastolicBloodPressure)}
                    />
                    {formik.touched.health_info?.diastolicBloodPressure && formik.errors.health_info?.diastolicBloodPressure && (
                        <Typography color="error">{formik.errors.health_info.diastolicBloodPressure}</Typography>
                    )}
                    </Grid>
                </Grid>
                ) : (
                <>
                    <Grid item container alignItems="center">
                        <Typography width="13vw">Systolic Blood Pressure</Typography>
                        <TextField
                            type="number"
                            placeholder="XX"
                            variant="outlined"
                            id="health_info.systolicBloodPressure"
                            name="health_info.systolicBloodPressure"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.health_info.systolicBloodPressure}
                            InputProps={{ endAdornment: 'mmHg'}}
                            sx={{
                                border: "1px solid #0000000D",
                                borderRadius: "4px",
                                width: "10vw"
                            }}
                            error={formik.touched.health_info?.systolicBloodPressure && formik.errors.health_info?.systolicBloodPressure}
                        />
                    </Grid>
                    {
                        formik.touched.health_info?.systolicBloodPressure && formik.errors.health_info?.systolicBloodPressure ?
                        (<Typography color="red" >{formik.errors.health_info.systolicBloodPressure}</Typography>) : null
                    }
                    <Grid item container alignItems="center">
                        <Typography width="13vw">Diastolic Blood Pressure</Typography>
                        <TextField
                            type="number"
                            placeholder="XX"
                            variant="outlined"
                            id="health_info.diastolicBloodPressure"
                            name="health_info.diastolicBloodPressure"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.health_info.diastolicBloodPressure}
                            InputProps={{ endAdornment: 'mmHg'}}
                            sx={{
                                border: "1px solid #0000000D",
                                borderRadius: "4px",
                                width: "10vw"
                            }}
                            error={formik.touched.health_info?.diastolicBloodPressure && formik.errors.health_info?.diastolicBloodPressure}
                        />
                    </Grid>
                    {
                        formik.touched.health_info?.diastolicBloodPressure && formik.errors.health_info?.diastolicBloodPressure ?
                        (<Typography color="red" >{formik.errors.health_info.diastolicBloodPressure}</Typography>) : null
                    }
                </>
                )}

                <Grid item container direction={isMobile ? "column" : "row"} alignItems={isMobile ? "left" : "center"} sx={isMobile && {marginBottom:1}}>
                    <Typography width={isMobile ? "100vw" : "13vw"}>Other Complications</Typography>
                    <FormGroup row aria-required sx={{width: "50%", display: "flex", justifyContent: "left", gap: "8px"}}>
                        <FormControlLabel
                            value="kidney"
                            control={
                                <Checkbox
                                    checked={formik.values.health_info.otherComplications.includes('kidney')}
                                    onChange={() => {handleComplicationCheckBox('kidney')}}
                                    sx={{"&.Mui-checked" : {
                                        color: "#0D1E83",
                                    }}}
                                />
                            }
                            label="Kidney"
                            sx={{
                                border: "1px solid rgba( 0, 0, 0, 0.05)",
                                borderRadius: "8px",
                                paddingRight: isMobile? "4px":"10px",
                                marginLeft: isMobile? "1px":"8px",
                                boxShadow: "1px 1px 10px 0 #4040400D"
                            }}
                        />
                        <FormControlLabel
                            value="neurological disorders"
                            control={
                                <Checkbox
                                    checked={formik.values.health_info.otherComplications.includes('neurological disorders')}
                                    onChange={() => {handleComplicationCheckBox('neurological disorders')}}
                                    sx={{"&.Mui-checked" : {
                                        color: "#0D1E83",
                                    }}}
                                />
                            }
                            label="Neurological Disorders"
                            sx={{
                                border: "1px solid rgba( 0, 0, 0, 0.05)",
                                borderRadius: "8px",
                                paddingRight: isMobile? "4px":"10px",
                                marginLeft: isMobile? "1px":"8px",
                                boxShadow: "1px 1px 10px 0 #4040400D"
                            }}
                        />
                        <FormControlLabel
                            value="heart"
                            control={
                                <Checkbox
                                    checked={formik.values.health_info.otherComplications.includes('heart')}
                                    onChange={() => {handleComplicationCheckBox('heart')}}
                                    sx={{"&.Mui-checked" : {
                                        color: "#0D1E83",
                                    }}}
                                />
                            }
                            label="Heart"
                            sx={{
                                border: "1px solid rgba( 0, 0, 0, 0.05)",
                                borderRadius: "8px",
                                paddingRight: isMobile? "4px":"10px",
                                marginLeft: isMobile? "1px":"8px",
                                boxShadow: "1px 1px 10px 0 #4040400D"
                            }}
                        />
                        <FormControlLabel
                            value="none"
                            control={
                                <Checkbox
                                    checked={formik.values.health_info.otherComplications.includes('none')}
                                    onChange={() => {handleComplicationCheckBox('none')}}
                                    sx={{"&.Mui-checked" : {
                                        color: "#0D1E83",
                                    }}}
                                />
                            }
                            label="None"
                            sx={{
                                border: "1px solid rgba( 0, 0, 0, 0.05)",
                                borderRadius: "8px",
                                paddingRight: isMobile? "4px":"10px",
                                marginLeft: isMobile? "1px":"8px",
                                boxShadow: "1px 1px 10px 0 #4040400D"
                            }}
                        />
                        <FormControlLabel
                            value="other"
                            control={
                                <Checkbox
                                    checked={formik.values.health_info.otherComplications.includes('other')}
                                    onChange={() => {handleComplicationCheckBox('other')}}
                                    sx={{"&.Mui-checked" : {
                                        color: "#0D1E83",
                                    }}}
                                />
                            }
                            label="Others"
                            sx={{
                                border: "1px solid rgba( 0, 0, 0, 0.05)",
                                borderRadius: "8px",
                                paddingRight: isMobile? "4px":"10px",
                                marginLeft: isMobile? "1px":"8px",
                                boxShadow: "1px 1px 10px 0 #4040400D"
                            }}
                        />
                    </FormGroup>
                </Grid>

                {isMobile ? (
                <Grid container spacing={2} alignItems="left" sx={{marginBottom:1}}>
                    <Grid item xs={12}>
                    <Typography width="100%">Visual Acuity*</Typography>
                    </Grid>
                    <Grid item xs={6} direction="row" container>
                    <Typography width="40%">Right</Typography>
                    <TextField
                        placeholder="XX/YY"
                        variant="outlined"
                        id="health_info.visualAcuity.right"
                        name="health_info.visualAcuity.right"
                        onKeyDown={(e) => {
                        if (e.code === 'Space') e.preventDefault();
                        }}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.health_info.visualAcuity.right}
                        sx={{
                        border: "1px solid #0000000D",
                        borderRadius: "4px",
                        width: "60%", // Adjusted for responsive layout
                        }}
                        error={formik.touched.health_info?.visualAcuity?.right && Boolean(formik.errors.health_info?.visualAcuity?.right)}
                    />
                    {/* {formik.touched.health_info?.visualAcuity?.right && formik.errors.health_info?.visualAcuity?.right && (
                        <Typography color="error">{formik.errors.health_info.visualAcuity.right}</Typography>
                    )} */}
                    </Grid>
                    <Grid item xs={6} direction="row" container>
                    <Typography width="40%">Left</Typography>
                    <TextField
                        placeholder="XX/YY"
                        variant="outlined"
                        id="health_info.visualAcuity.left"
                        name="health_info.visualAcuity.left"
                        onKeyDown={(e) => {
                        if (e.code === 'Space') e.preventDefault();
                        }}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.health_info.visualAcuity.left}
                        sx={{
                        border: "1px solid #0000000D",
                        borderRadius: "4px",
                        width: "60%",
                        //   ml: "8px",
                        }}
                        error={formik.touched.health_info?.visualAcuity?.left && Boolean(formik.errors.health_info?.visualAcuity?.left)}
                    />
                    </Grid>
                </Grid>
                ) : (
                    <Grid item container direction={isMobile ? "column" : "row"} alignItems={isMobile ? "left" : "center"} sx={isMobile && {marginBottom:1}}>
                    <Typography width={isMobile ? "100vw" : "13vw"}>Visual Acuity*</Typography>
                    <Typography>Right</Typography>
                    <TextField
                        placeholder="XX/YY"
                        variant="outlined"
                        id="health_info.visualAcuity.right"
                        name="health_info.visualAcuity.right"
                        onKeyDown={(e) => {
                            if(e.code === 'Space')
                                e.preventDefault();
                        }}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.health_info.visualAcuity.right}
                        sx={{
                            border: "1px solid #0000000D",
                            borderRadius: "4px",
                            width: "6vw",
                            ml: "8px",
                        }}
                        error={formik.touched.health_info?.visualAcuity?.right && formik.errors.health_info?.visualAcuity?.right}
                    />
                    <Typography sx={{ml: "1vw"}}>Left</Typography>
                    <TextField
                        placeholder="XX/YY"
                        variant="outlined"
                        id="health_info.visualAcuity.left"
                        name="health_info.visualAcuity.left"
                        onKeyDown={(e) => {
                            if(e.code === 'Space')
                                e.preventDefault();
                        }}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.health_info.visualAcuity.left}
                        sx={{
                            border: "1px solid #0000000D",
                            borderRadius: "4px",
                            width: "6vw",
                            ml: "8px",
                        }}
                        error={formik.touched.health_info?.visualAcuity?.left && formik.errors.health_info?.visualAcuity?.left}
                    />
                </Grid>
                )}


                {
                    formik.touched.health_info?.visualAcuity?.left && formik.errors.health_info?.visualAcuity?.left ?
                    (<div style={{display: "block", width: "100%"}}>
                        <Typography color="red">{formik.errors.health_info.visualAcuity.left}</Typography>
                    </div>) : null
                }
                {
                    formik.touched.health_info?.visualAcuity?.right && formik.errors.health_info?.visualAcuity?.right ?
                    (<Typography color="red" >{formik.errors.health_info.visualAcuity.right}</Typography>) : null
                }
                <Grid item container direction={isMobile ? "column" : "row"} alignItems={isMobile ? "left" : "center"} sx={isMobile && {marginBottom:1}}>
                    <Typography width={isMobile ? "100vw" : "13vw"}>Family History of <br/>Diabetes</Typography>
                    <FormGroup row aria-required sx={{marginLeft: isMobile? "1px":"10px"}}>
                        <FormControlLabel
                            value="parent"
                            control={
                                <Checkbox
                                    checked={formik.values.health_info.familyHistory.includes('parent')}
                                    onChange={() => {handleFaimlyCheckBox('parent')}}
                                    sx={{"&.Mui-checked" : {
                                        color: "#0D1E83",
                                    }}}
                                />
                            }
                            label="Parent(s)"
                            sx={{
                                border: "1px solid rgba( 0, 0, 0, 0.05)",
                                borderRadius: "8px",
                                paddingRight: isMobile? "4px":"10px",
                                boxShadow: "1px 1px 10px 0 #4040400D"
                            }}
                        />
                        <FormControlLabel
                            value="sibling"
                            control={
                                <Checkbox
                                    checked={formik.values.health_info.familyHistory.includes('sibling')}
                                    onChange={() => {handleFaimlyCheckBox('sibling')}}
                                    sx={{"&.Mui-checked" : {
                                        color: "#0D1E83",
                                    }}}
                                />
                            }
                            label="Sibling(s)"
                            sx={{
                                border: "1px solid rgba( 0, 0, 0, 0.05)",
                                borderRadius: "8px", 
                                paddingRight: isMobile? "4px":"10px",
                                boxShadow: "1px 1px 10px 0 #4040400D",
                                marginLeft: isMobile? "1px":"8px"
                            }}
                        />
                        <FormControlLabel
                            value="none"
                            control={
                                <Checkbox
                                    checked={formik.values.health_info.familyHistory.includes('none')}
                                    onChange={() => {handleFaimlyCheckBox('none')}}
                                    sx={{"&.Mui-checked" : {
                                        color: "#0D1E83",
                                    }}}
                                />
                            }
                            label="None"
                            sx={{
                                border: "1px solid rgba( 0, 0, 0, 0.05)",
                                borderRadius: "8px",
                                paddingRight: isMobile? "4px":"10px",
                                boxShadow: "1px 1px 10px 0 #4040400D",
                                marginLeft: isMobile? "1px":"8px"
                            }}
                        />
                    </FormGroup>
                </Grid>
                <Grid item container direction={isMobile ? "column" : "row"} alignItems={isMobile ? "left" : "center"}>
                    <Typography width={isMobile ? "100vw" : "13vw"}>Notes</Typography>
                    <TextField
                        placeholder="Other Vital Information"
                        multiline
                        id="health_info.notes"
                        name="health_info.notes"
                        onChange={formik.handleChange}
                        value={formik.values.health_info.notes}
                        rows={3}
                        sx={{
                            border: "1px solid #0000000D",
                            borderRadius: "4px",
                            width: isMobile ? "100%" : "25vw",
                        }}
                    />
                </Grid>
            </Grid>
        </Container>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: "16px", gap: "2.5%"}}>
            <Button onClick={handleBack} sx={{color: "#0D1E83"}}>Return</Button>
            <Button
                    variant='contained'
                    disabled={disabledButton}
                    onClick={handleNext}
                    borderRadius="8px"
                    fontWeight="400"
                    fontSize="16px"
                    style={{
                        padding: "12px 24px",
                        border: disabledButton ? "1px solid rgba(125, 121, 123, 0.50)" : "1px solid #7D797B80",
                        background: disabledButton ?
                        "var(--Grey, #F4EFF0)" :
                        'linear-gradient(270deg, #051572 7.32%, #0820B4 92.68%), linear-gradient(0deg, #FFFFFF, #FFFFFF)',
                    }}
                    endIcon={<ArrowForwardIcon />}
                >
                    Proceed
                </Button>
        </div>
    </div>
  )
}

export default HealthInformation