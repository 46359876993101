import { Box, Button, Checkbox, FormControlLabel, Typography, useTheme, useMediaQuery, } from '@mui/material';
import React, { useState } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import TranslateIcon from "../../assests/images/translate-icon.jpg";
import CloseIcon from '@mui/icons-material/Close';
import "./informedConsent.css";

const InformedConsent = ({ handleProceed, handleExit }) => {
    const [engContent, setEngContent] = useState(true);
    const [isChecked, setIsChecked] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const handlePopupClick = (event) => {
        event.stopPropagation();
    };

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    const handleContentChange = () => {
        setEngContent(!engContent);
    }

    const getEngContent = () => {
        return(
            <Typography
                paddingX="24px"
                fontWeight={400}
                fontSize="16px"
            >
                We, the E-Health AI Unit team (MoHFW), in collaboration with AIIMS Delhi & AIIMS Rishikesh, are developing an AI-based Diabetic Retinopathy solution for Optometrists and Ophthalmologists. To ensure we create an effective and user-friendly product, we need to understand our potential users better.<br/><br/>
                During the pilot testing, we will use our AI tool to produce inferences and test the product in real-world settings. All data collected, including fundoscopy scan images and health information, will be treated with utmost care and in compliance with data protection laws. Your health information will be kept strictly confidential and solely used for research purposes  to enhance our product's functionality.<br/><br/>
                By agreeing to participate, you acknowledge and understand the following:<br/>
                1. Fundoscopy scan images will be used to evaluate the AI model and improve its performance.<br/>
                2. Health information will be collected and used for research purposes to enhance the product.<br/>
                3. Your participation is voluntary, and you can withdraw your consent or discontinue participation at any time.<br/><br/>
                We assure you that any information collected during the session will be kept confidential and will not be shared outside E-Health Team without your consent.
            </Typography>
        );
    };

    const getHinContent = () => {
        return(
            <Typography
                paddingX="24px"
                fontWeight={400}
                fontSize="16px"
            >
                हम, ई-हेल्थ ए-आई यूनिट टीम (MoHFW), एम्स दिल्ली और एम्स ऋषिकेश के सहयोग से, ऑप्टोमेट्रिस्ट और नेत्र रोग विशेषज्ञों के लिए एआई-आधारित डायबिटिक रेटिनोपैथी समाधान विकसित कर रहे हैं। यह सुनिश्चित करने के लिए कि हम एक प्रभावी और उपयोगकर्ता-अनुकूल उत्पाद बनाएं, हमें अपने संभावित उपयोगकर्ताओं को बेहतर ढंग से समझने की आवश्यकता है।<br /><br />
                पायलट परीक्षण के दौरान, हम निष्कर्ष निकालने और वास्तविक दुनिया की सेटिंग में उत्पाद का परीक्षण करने के लिए अपने एआई टूल का उपयोग करेंगे। फंडोस्कोपी स्कैन छवियों और स्वास्थ्य जानकारी सहित एकत्र किए गए सभी डेटा का अत्यधिक सावधानी से और डेटा सुरक्षा कानूनों के अनुपालन में व्यवहार किया जाएगा। आपकी स्वास्थ्य जानकारी को पूरी तरह से गोपनीय रखा जाएगा और इसका उपयोग केवल हमारे उत्पाद की कार्यक्षमता को बढ़ाने के लिए अनुसंधान उद्देश्यों के लिए किया जाएगा।<br /><br />
                भाग लेने के लिए सहमत होकर, आप निम्नलिखित को स्वीकार करते हैं और समझते हैं:<br />
                1.  फंडोस्कोपी स्कैन छवियों का उपयोग एआई मॉडल का मूल्यांकन करने और उसके प्रदर्शन को बेहतर बनाने के लिए किया जाएगा।<br />
                2.  स्वास्थ्य संबंधी जानकारी एकत्र की जाएगी और उत्पाद को बेहतर बनाने के लिए अनुसंधान उद्देश्यों के लिए उपयोग किया जाएगा।<br />
                3.  आपकी भागीदारी स्वैच्छिक है, और आप किसी भी समय अपनी सहमति वापस ले सकते हैं या भागीदारी बंद कर सकते हैं।<br /><br />
                हम आपको आश्वस्त करते हैं कि सत्र के दौरान एकत्र की गई कोई भी जानकारी गोपनीय रखी जाएगी और आपकी सहमति के बिना ई-हेल्थ टीम के बाहर साझा नहीं की जाएगी।
            </Typography>
        );
    };

    const getlabelContent = () => {
        return (
            engContent ?
                (<Typography fontWeight={400} fontSize="16px">
                    By checking the box, I confirm that I have read and understood the information provided and I willingly give consent.
                </Typography>)
                :
                (<Typography fontWeight={400} fontSize="16px">
                    बॉक्स को चेक करके, मैं पुष्टि करता हूं कि मैंने प्रदान की गई जानकारी को पढ़ और समझ लिया है और मैं स्वेच्छा से सहमति देता हूं।
                </Typography>)
        );
    };

    // CSS for responsive width and scrollability
    const boxStyle = {
        padding: "22px",
        border: "1px solid rgba(0, 0, 0, 0.05)",
        borderRadius: "8px",
        marginX: { sm: "20vw" },
        width: { sm: "auto" },
        maxHeight: { sm: "auto" },
        overflowY: { sm: "hidden" },
        '@media (max-width: 900px)': {
            marginX: "0", 
            width: "100vw",
            maxHeight: "95vh", 
            overflowY: "auto",
        },
        background: "var(--white, #FFF)",
        boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.08), 0px 0px 6px 0px rgba(0, 0, 0, 0.02)"
    };

    return (
        <div className="popup">
            <Box
                sx={boxStyle}
                onClick={handlePopupClick}
            >
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    <Typography fontWeight="500" fontSize="24px" lineHeight="27.05px">{engContent ? "Informed Consent" : "सूचित सहमति"}</Typography>
                    <Button
                        className='closeButton'
                        endIcon={<CloseIcon />}
                        sx={{ color: "var(--blue, #0D1E83)", borderRadius: "8px", border: "1px solid #0D1E83" }}
                        onClick={() => handleExit()}
                    >
                        Close
                    </Button>
                </div>
                <div style={{ marginTop: "20px" }}>
                    {engContent ? getEngContent() : getHinContent()}
                </div>
                {isMobile && <div>
                    <Button
                        onClick={handleContentChange}
                        className='contentButton'
                        startIcon={<img src={TranslateIcon} alt='Translate' width="17px" height="17px" />}
                        sx={{ borderRadius: "8px", color: "#0D1E83", border: "1px solid #0D1E83" }}
                    >
                        {engContent ? <Typography fontSize="20px" fontWeight="400" align='center'>हिंदी में पढ़ें</Typography> : <Typography fontSize="16px" fontWeight="400" align='center'>Read in English</Typography>}
                    </Button>
                </div>}
                <div className='checkboxDiv'>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isChecked}
                                onChange={handleCheckboxChange}
                                sx={{ "&.Mui-checked": { color: "#0D1E83" } }}
                            />
                        }
                        label={getlabelContent()}
                    />
                </div>
                
                <div className='buttonDiv'>
                    {!isMobile && <Button
                        onClick={handleContentChange}
                        className='contentButton'
                        startIcon={<img src={TranslateIcon} alt='Translate' width="17px" height="17px" />}
                        sx={{ borderRadius: "8px", color: "#0D1E83", border: "1px solid #0D1E83" }}
                    >
                        {engContent ? <Typography fontSize="20px" fontWeight="400" align='center'>हिंदी में पढ़ें</Typography> : <Typography fontSize="16px" fontWeight="400" align='center'>Read in English</Typography>}
                    </Button>}
                    <Button
                        variant='contained'
                        disabled={!isChecked}
                        border="1px solid #7D797B80"
                        borderRadius="8px"
                        fontWeight="400"
                        fontSize="16px"
                        style={{
                            background: isChecked ? 'linear-gradient(270deg, #051572 7.32%, #0820B4 92.68%), linear-gradient(0deg, #FFFFFF, #FFFFFF)' : '',
                            padding: "12px 24px"
                        }}
                        endIcon={<ArrowForwardIcon />}
                        onClick={handleProceed}
                    >
                        Proceed
                    </Button>
                </div>
            </Box>
        </div>
    );
}

export default InformedConsent;
