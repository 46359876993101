/* eslint react-hooks/exhaustive-deps: "off" */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import config from "../../config";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import PatientDetailsComponent from "../patientDetailsComponent/patientDetailsComponent";
import InferenceDetailsComponent from "../inferenceDetailsComponent/inferenceDetailsComponent";
import LoadingComponent from "../loadingComponent/loadingComponent";
import HealthDetailsComponent from "../healthDetailsComponent/healthDetailsComponent";
import DisclaimerComponent from "../disclaimerComponent/disclaimerComponent";
import PdfTemplate from "../pdfTemplate/pdfTemplate";
import { nextSteps, handleSetDR, handleSetReferral } from "../../utlis";

const ReportsComponent = () => {
  const navigate = useNavigate();
  const { patientrecordId } = useParams();
  const header = {
    Authorization: "Token " + localStorage.getItem("optha-token"),
  };
  const [reportData, setReportData] = useState({});
  const [patientData, setPatientData] = useState({});
  const [docData, setDocData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [downloadPdfFlag, setDownloadPdfFlag] = useState(false);
  const [fileName, setFileName] = useState("");
  const [images, setImages] = useState([]);
  const [obj, setObj] = useState();

  useEffect(() => {
    handleAPICall();
  }, [patientrecordId]);

  const handleAPICall = async () => {
    setIsLoading(true);
    try {
      const reportResponse = await axios.get(
        `${config.API_BASE_URL}/api/reports/getreport/?uuid=${patientrecordId}`,
        { headers: header }
      );
      const report = reportResponse.data.results[0];
      setReportData(report);
      setPatientData(report.patient);
      setDocData(report.doctor);
      imagetoBase64(report.files.left, report.files.right);
      if (report.patient.name) {
        setFileName(report.patient.name.replace(" ", "_"));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // const handleSetReferral = (left, right) => {
  //   if (
  //     (left === "No Diabetic Retinopathy" ||
  //       left === "Mild Diabetic Retinopathy") &&
  //     (right === "No Diabetic Retinopathy" ||
  //       right === "Mild Diabetic Retinopathy")
  //   ) {
  //     return "Not Required";
  //   } else {
  //     return "Required";
  //   }
  // };

  // const handleSetDR = (classification, gradability) => {
  //   if (
  //       gradability?.predicted_class_name === "Non Gradable" &&
  //     classification === undefined
  //   ) {
  //     return "Hazy image (due to cataract or significant corneal opacity)";
  //   } else {
  //     return classification?.predicted_class_name;
  //   }
  // };

  const handleBackClick = () => {
    navigate("/patientrecord");
  };
  function imagetoBase64(imageURL1, imageURL2) {
    // Create an array of promises for fetching the images
    const fetchPromises = [fetch(imageURL1), fetch(imageURL2)];
    // Use Promise.all to wait for both image fetch requests to complete
    Promise.all(fetchPromises)
      .then((responses) => {
        // Extract the Blob data for each image
        const imageBlobs = responses.map((response) => response.blob());
        // Process the image Blobs as needed
        Promise.all(imageBlobs)
          .then((blobs) => {
            // blobs[0] contains the Blob for the first image
            // blobs[1] contains the Blob for the second image
            // You can convert the Blobs to URLs or Base64 strings here
            const imageURLs = blobs.map((blob) => URL.createObjectURL(blob));
            // Use imageURLs as needed (e.g., display the images)
            // console.log("Image URLs:", imageURLs);
            setImages(imageURLs);
          })
          .catch((error) => {
            console.error("Error processing image Blobs:", error);
          });
      })
      .catch((error) => {
        console.error("Error fetching images:", error);
      });
  };
  function createObj() {
    const leftInference = handleSetDR(
      reportData?.ml_model?.classification?.left,
      reportData.ml_model?.gradibility?.left
    );
    const rightInference = handleSetDR(
      reportData?.ml_model?.classification?.right,
      reportData?.ml_model?.gradibility?.right
    );
    const { message } = nextSteps(leftInference, rightInference);
    const obje = {
      heading: "Diabetic Retinopathy Screening Report",
      patientDetails: [
        {
          label: "Patient Name:",
          value: `${patientData?.name[0].toUpperCase()}${patientData?.name.slice(
            1
          )}`,
        },
        { label: "Age:", value: `${patientData?.age} Year` },
        {
          label: "Gender:",
          value: `${patientData?.sex[0].toUpperCase()}${patientData?.sex.slice(
            1
          )}`,
        },
        {
          label: "Patient ID:",
          value:
            patientData?.other_id !== null
              ? patientData?.other_id
              : patientData?.abha_id,
        },
        { label: "Phone Number:", value: `+91 ${patientData?.phone}` },
        { label: "Referred by:", value: `${docData?.name}` },
      ],
      url: reportData?.uuid,
      images: {
        img1: images[0],
        img2: images[1],
      },
      healthInfo: [
        {
          label: "Pre-diagnosed Diabetes:",
          value:
            reportData.health_info?.preDiagnosedDiabetes !== ""
              ? `${reportData.health_info?.preDiagnosedDiabetes[0].toUpperCase()}${reportData.health_info?.preDiagnosedDiabetes.slice(
                  1
                )}`
              : "N/A",
        },
        {
          label: "Duration of Diabetes Detection:",
          // value: processInfo(report_data.health_info, "durationDiabetesDetection", "Year"),
          value:
            reportData.health_info?.durationDiabetesDetection !== ""
              ? `${reportData.health_info?.durationDiabetesDetection} Years`
              : "N/A",
        },
        {
          label: "Diabetes Type:",
          // value: processInfo(report_data.health_info, "diabetesType", "")
          value:
            reportData.health_info?.diabetesType !== ""
              ? `${reportData.health_info?.diabetesType[0].toUpperCase()}${reportData.health_info?.diabetesType.slice(
                  1
                )}`
              : "N/A",
        },
        {
          label: "Hb A1 C Level:",
          // value: processInfo(report_data.health_info, "HbA1CLevel", "mg/dL")
          value:
            reportData.health_info?.HbA1CLevel !== ""
              ? `${reportData.health_info?.HbA1CLevel} mg/dL`
              : "N/A",
        },
        {
          label: "Systolic Blood Pressure:",
          // value: processInfo(report_data.health_info, "bloodPressure", "mm/Hg"),
          value:
            reportData.health_info?.systolicBloodPressure !== "" && reportData.health_info?.systolicBloodPressure !== undefined
              ? `${reportData.health_info?.systolicBloodPressure} mm/Hg`
              : "N/A",
        },
        {
          label: "Diastolic Blood Pressure:",
          // value: processInfo(report_data.health_info, "bloodPressure", "mm/Hg"),
          value:
            reportData.health_info?.diastolicBloodPressure !== "" && reportData.health_info?.diastolicBloodPressure !== undefined
              ? `${reportData.health_info?.diastolicBloodPressure} mm/Hg`
              : "N/A",
        },
        {
          label: "Visual Acuity:",
          value: `Right: ${
            reportData.health_info?.visualAcuity?.right !== ""
              ? reportData.health_info?.visualAcuity?.right
              : "N/A"
          }, Left: ${
            reportData.health_info?.visualAcuity?.left !== ""
              ? reportData.health_info?.visualAcuity?.left
              : "N/A"
          }`,
        },
        {
          label: "Other complications:",
          value: reportData.health_info?.otherComplications.length > 0
            ? reportData.health_info?.otherComplications
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(", ")
            : "N/A",
        },
        {
          label: "Family History of Diabetes:",
          value: reportData.health_info?.familyHistory.length > 0
            ? reportData.health_info?.familyHistory
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(", ")
            : "N/A",
        },
      ],
      notes:
        reportData.health_info?.notes !== ""
          ? reportData.health_info?.notes
          : "N/A",
      nextSteps: `${message} and suggest them to monitor their sugar level.`,
      hospitalVisit: `If you’re looking for a medical advise, visit Dr. ${docData?.name} at ${docData?.metadata?.hospital !== "" ? docData?.metadata?.hospital : "AIIMS DELHI"} Hospital.`,
      disclaimer:
        "The report does not replace professional medical advice, diagnosis or treatment",
    };
    setObj(obje);
    setDownloadPdfFlag(true);
  }

  return (
    <div>
      <div>
        <Stack
          direction="row"
          padding="10px 5%"
          justifyContent="space-between"
          alignItems="center"
        >
          <IconButton onClick={handleBackClick}>
            <ArrowBackIcon />
          </IconButton>
          <Button
            startIcon={<LocalPrintshopOutlinedIcon />}
            sx={{
              borderRadius: "8px",
              border: "2px solid var(--Blue, #0D1E83)",
              background: "var(--Blue, #0D1E83)",
              color: "#FFFFFF",
              "&:hover": {
                background: "var(--Blue, #0D1E83)",
              },
            }}
            onClick={() => createObj()}
          >
            Print Report
          </Button>
        </Stack>
      </div>
      <Divider />
      <div style={{padding: isMobile ? "0%" : "2.5% 12.5%" }}>
        <Box
          sx={{
            border: "1px solid var(--BoxStroke, rgba(0, 0, 0, 0.05))",
            borderRadius: "8px",
            background: "var(--White, #FFF)",
            boxShadow:
              "0px 2px 4px 0px rgba(0, 0, 0, 0.08), 0px 0px 6px 0px rgba(0, 0, 0, 0.02)",
          }}
        >
          <Box width="100%" sx={{ background: "rgba(189, 215, 242, 0.14)" }}>
            <PatientDetailsComponent
              pat_name={patientData.name}
              pat_age={patientData.age}
              pat_sex={patientData.sex}
              visitDate={new Date(reportData.created_at).toDateString()}
              pat_id={patientData.other_id || patientData.abha_id}
              pat_phone={patientData.phone}
              docName={docData.name}
            />
          </Box>
          <Box sx={{ padding: "1% 7.5%" }}>
            <InferenceDetailsComponent
              leftImage={reportData?.files?.left}
              rightImage={reportData?.files?.right}
              leftInference={handleSetDR(
                reportData?.ml_model?.classification?.left,
                reportData.ml_model?.gradability?.left
              )}
              rightInference={handleSetDR(
                reportData?.ml_model?.classification?.right,
                reportData?.ml_model?.gradability?.right
              )}
              required={handleSetReferral(
                reportData?.ml_model?.classification?.left
                  ?.predicted_class_name,
                reportData?.ml_model?.classification?.right
                  ?.predicted_class_name
              )}
            />
          </Box>
          <Accordion defaultExpanded={true}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                padding: "0 7.5%",
                display: "flex",
                alignItems: "center",
                background: "#BDD7F21F",
              }}
            >
              <Typography fontWeight={600} fontSize="20px" color="#181818">
                Health Information
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: "0 7.5%" }}>
              <HealthDetailsComponent health_info={reportData.health_info} />
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded={true}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                padding: "0 7.5%",
                display: "flex",
                alignContent: "center",
                background: "#BDD7F21F",
              }}
            >
              <Typography fontWeight={600} fontSize="20px" color="#181818">
                Disclaimer
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: "2% 7.5%" }}>
              <DisclaimerComponent />
            </AccordionDetails>
          </Accordion>
        </Box>
      </div>
      <LoadingComponent open={isLoading} text={false} />
      {downloadPdfFlag && obj ? (
        <PdfTemplate
          downloadFlag={downloadPdfFlag}
          fileName={fileName}
          heading={obj.heading}
          patientDetails={obj.patientDetails}
          url={obj.url}
          images={obj.images}
          healthInfo={obj.healthInfo}
          notes={obj.notes}
          nextSteps={obj.nextSteps}
          hospitalVisit={obj.hospitalVisit}
          disclaimer={obj.disclaimer}
          setDownloadFlag={setDownloadPdfFlag}
        />
      ) : null}
    </div>
  );
};

export default ReportsComponent;
