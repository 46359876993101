import React, { useState } from "react";
import { Box, Button, Typography, useTheme, useMediaQuery } from "@mui/material";
import UploadIcon from '@mui/icons-material/Upload';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import PreviewImage from "./previewImage";


const ImageUploaded = ({files, resetFile, resetGradable, eye, setDisabledProceed}) => {
    const [open, setOpen] = useState(false);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const handlePreviewOpen = () => {
        setOpen(true);
    };

    const handlePreviewClose = () => {
        setOpen(false);
    };

    const handleReUpload = () => {
        resetFile(null);
        resetGradable();
        setDisabledProceed(true);
    }

  return (
    <div style={{display: "flex", justifyItems: "center", alignItems: "center", padding: "10px", flexDirection: isMobile ? "column":"row"}}>
        <div style={{ width: isMobile?"11vw":"7.5vw", height: isMobile?"11vw":"9.5vh", borderRadius: "4px"}}>
            {Array.isArray(files) ? (
                <img alt="" src={URL.createObjectURL(files[0].file)} style={{ borderRadius: "4px", width: "100%", height: "100%", objectFit: "fill"}}/>
            ) : (
                <img alt="" src={URL.createObjectURL(files)} style={{ borderRadius: "4px", width: "100%", height: "100%", objectFit: "fill"}}/>
            )}
        </div>
        <Box marginLeft={1}>
            <Typography fontSize="11px" fontWeight={400}>Image Uploaded!</Typography>
            {!isMobile && <Typography fontSize="8px" fontWeight={400} color="#404040">View image to confirm image quality.</Typography>}
            <Box
            sx={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                justifyContent: isMobile ? 'center' : 'flex-start',
                alignItems: isMobile ? 'center' : 'flex-start',
                height: isMobile ? '100%' : 'auto',
                width: '100%',
                gap: 1,
            }}
        >
            <Button
                onClick={() => handleReUpload()}
                startIcon={<UploadIcon fontSize="7px" style={{ color: "#FD284A" }} />}
                sx={{
                    color: "#404040",
                    border: "1px solid #0000000D",
                    fontSize: "9px",
                    fontWeight: "400",
                    borderRadius: "4px",
                    marginTop: isMobile ? "10px" : 0,
                }}
            >
                Re-upload
            </Button>
            <Button
                onClick={() => handlePreviewOpen()}
                startIcon={<OpenInFullIcon fontSize="7px" style={{ color: "#0D1E83" }} />}
                sx={{
                    color: "#404040",
                    border: "1px solid #0000000D",
                    fontSize: "9px",
                    fontWeight: "400",
                    borderRadius: "4px",
                    marginTop: isMobile ? "10px" : 0,
                }}
            >
                View Image
            </Button>
        </Box>
        </Box>
        {open && <PreviewImage open={open} handlePreviewClose={handlePreviewClose} files={files} eye={eye}/>}
    </div>
  )
}

export default ImageUploaded