import React, { Component } from 'react';
import * as Sentry from '@sentry/react'; // Import Sentry if you're using it for error tracking

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
    };
  }

  // This method is called when an error is thrown in any of the child components
  static getDerivedStateFromError(error) {
    // Update state to display fallback UI
    return { hasError: true };
  }

  // This method is called after an error has been caught
  componentDidCatch(error, errorInfo) {

    // Optionally log the error to an external service like Sentry
    Sentry.captureException(error, {
      extra: {
        errorInfo,
        componentStack: errorInfo.componentStack,
      },
    });
    
    // You could also send the error information to a custom logging service
    // sendToCustomLoggingService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // Customize your fallback UI
      return (
        <div style={{ padding: '20px', textAlign: 'center' }}>
          <h1>Oops! Something went wrong.</h1>
          <p>We are sorry for the inconvenience. Please try again later.</p>
          {/* Optionally, include a button to refresh or go back */}
          <button onClick={() => window.location.reload()}>Try Again</button>
        </div>
      );
    }

    // Render children if no error
    return this.props.children;
  }
}

export default ErrorBoundary;
