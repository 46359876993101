import React, { useEffect, useState } from "react"
import Header from '../header/header';
import "./styles.css";
import dashboardOptha from "../../assests/images/dashboardOptha.svg";
import addButton from "../../assests/images/addButton.svg";
import InformationCard from "../informationCard/informationCard";
import { Typography, Box, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PrintDialogBox from "./printDialogBox";
import axios from "axios";
import config from "../../config";
/* eslint react-hooks/exhaustive-deps: "off" */

const Dashboard = () => {
    const navigate = useNavigate();
    const header = {"Authorization": "Token "+localStorage.getItem("optha-token")};
    const [isDialog, setIsDialog] = useState(localStorage.getItem("report_id") ? true : false);
    const [statsData, setStatsData] = useState({});
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        axios
        .get(`${config.API_BASE_URL}/api/reports/dashboard/`, {headers: header})
        .then(res => res.data)
        .then(data => setStatsData(data))
        .catch((err) => console.log(err));
    }, []);

    const stats_info = [
        {"heading": "Total Patients Screened", "number": "", "time": "Since Jan 2023", "key": "total_patients_screened",},
        {"heading": "Total DR Positive Patients", "number": "", "time": "Cases Since Jan 2023", "key": "total_positive_patients",},
        {"heading": "Total Referable Patients", "number": "", "time": "Cases Since Jan 2023", "key": "total_referable_patients",},
        {"heading": "Average Patients Per Day", "number": "", "time": "Average Patients Screened Per day", "key": "average_patients_per_day"}
    ];

    const addPatient = () => {
        localStorage.removeItem("patient_id");
        localStorage.removeItem("doc_name");
        navigate("/patientscreen");
    };

    return (
        <>
            {!isMobile && <Header />}
            <div className="dashboardDiv">
                {!isMobile && (
                    <div style={{ width: "50%" }}>
                        <img src={dashboardOptha} alt="Opthalmologist" style={{ width: "100%", height: "100%" }} />
                    </div>
                )}
                <div className="rightcolumn">
                    <div className="informationGrid">
                        {stats_info.map((info, index) => (
                            <InformationCard
                                key={index}
                                heading={info.heading}
                                number={statsData[info.key] !== undefined ? Math.round(statsData[info.key]) : ''}
                                time={info.time}
                                percentage={Math.round((statsData[info.key] / statsData["total_patients_screened"]) * 100)}
                                isMobile={isMobile}
                            />
                        ))}
                    </div>
                    <Box className="addDiv" onClick={() => { addPatient() }}>
                        <Box className="addImg">
                            <img src={addButton} style={{ width: "90%", height: "90%" }} alt="Add" />
                        </Box>
                        <Box className="addtext">
                            <Typography fontSize={{ xs: '16px', md: '20px' }} fontWeight="500">Add Patient Records</Typography>
                            {!isMobile && (
                                <Typography sx={{ opacity: "80%" }} fontSize={{ xs: '12px', md: '14px' }} fontWeight={500}>Make patient report and share it with an Ophthalmologist with comprehensive diagnosis, only in a few clicks.</Typography>
                            )}
                        </Box>
                    </Box>
                </div>
            </div>
            <PrintDialogBox open={isDialog} setIsDialog={setIsDialog} addPatient={addPatient} />
        </>
    )
}

export default Dashboard