import React from "react";
import { Box, Button, Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';


const PreviewImage = ({open, handlePreviewClose, files, eye}) => {
    // const [answer, setAnswer] = useState(false);

    // const handleAnswerClick = (value) => {
    //     setAnswer(true);
    // };

    // const handleUndoClick = () => {
    //     setAnswer(false);
    // }
  return (
    <div>
        <Dialog open={open} fullWidth={true} maxWidth="md" height="md">
            <DialogTitle display="flex" justifyContent="space-between" alignItems="center">
                <Typography fontWeight={500} fontSize="28px" color="#0D1E83">
                    Captured Image of {eye}
                </Typography>
                <Button endIcon={<CloseIcon />} onClick={handlePreviewClose} sx={{color: "#0D1E83"}}>Close</Button>
            </DialogTitle>
            <DialogContent sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <Box width="max-content" height="40vh">
                    {Array.isArray(files) && files.length > 0 ?
                        (
                        <img
                            src={URL.createObjectURL(files[0].file)}
                            alt={files[0].file.name}
                            style={{ borderRadius: "4px", width: "100%", height: "100%", objectFit: "contain"}}
                        />
                        ) : (
                        <img
                            src={URL.createObjectURL(files)}
                            alt={files.name}
                            style={{ borderRadius: "4px", width: "100%", height: "100%", objectFit: "contain"}}
                        />
                        )
                    }
                </Box>
            </DialogContent>
            {/* <DialogActions>
                <Box width="md" display="flex" justifyContent="flex-end" alignItems="center" paddingRight="25px">
                    {
                        answer ?
                        (<>
                            <Typography fontWeight={500} fontSize="16px" mr="8px">
                                Gradeability Feedback is saved. Close this window to proceed.
                            </Typography>
                            <Button
                                borderRadius="8px"
                                sx={{
                                    color: "#0D1E83",
                                    border: "1px solid #0000000D"
                                }}
                                startIcon={<ReplayIcon />}
                                onClick={handleUndoClick}
                            >
                                Undo
                            </Button>
                        </>)
                        :
                        (<>
                            <Typography fontWeight={500} fontSize="16px" mr="8px">Is the Image Gradable?</Typography>
                            <Button
                                borderRadius="8px"
                                sx={{
                                    color: "#075400",
                                    background: "#C7F5B7",
                                    border: "1px solid #C7F5B7",
                                    marginRight: "8px"
                                }}
                                startIcon={<DoneIcon />}
                                onClick={handleAnswerClick}
                            >
                                    Yes
                            </Button>
                            <Button
                                borderRadius="8px"
                                sx={{
                                    color: "#9F0101",
                                    background: "#FDD2D2",
                                    border: "1px solid #FDD2D2",
                                }}
                                startIcon={<CloseIcon />}
                                onClick={handleAnswerClick}
                            >
                                No
                            </Button>
                        </>)
                    }
                </Box>
            </DialogActions> */}
        </Dialog>
    </div>
  )
}

export default PreviewImage